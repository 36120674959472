<template>

  <DefaultCrudView
    @switch-inactive="switchInactive"
    @create-new="newCompany"
    :breadcrumbsItems="breadcrumbs"
    :actions="actions"
    :inactiveFilterValue="inactiveFilterValue"
    showInactiveFilter
    containerLarge
  >
    <template slot="content">

      <v-data-table
        :headers="headers"
        :items="companies"
        class="elevation-1"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :item-class="itemRowStyle"
        :footer-props="$defaultDataTableFooterProps"
      >
        <template v-slot:item.tenant_id="{ item }">
          {{ getTenantTitle(item.tenant_id) }}
        </template>

        <template v-slot:item.actions="{ item }">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                @click.stop="editCompany(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{ $t('edit') }}</span>
          </v-tooltip>
          
        </template>
      </v-data-table>

      <DefaultModal :title="getModalTitle()" ref="modal" @submit="save">
        <template v-slot:content>
          <DynamicFormContent :fields="fields"></DynamicFormContent>
        </template>
      </DefaultModal>

    </template>
  </DefaultCrudView>

</template>

<style scoped>
  .container {
    padding: 0;
  }

  .table-container {
    margin-top: 20px;
  }

  >>>.inactive-row {
    color: #616161;
    opacity: 0.6;
  }
</style>

<script>

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import DefaultCrudView from '@/components/DefaultCrudView.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import DynamicFormContent from '@/components/DynamicFormContent.vue'

import TenantActions from '@/store/core/tenant/actions-types'
import CompanyActions from '@/store/core/company/actions-types'
import CompanyMutations from '@/store/core/company/mutations-types'
import AppActions from '@/store/app/actions-types'

export default {
  name: "Company",

  components: {
    DefaultCrudView,
    DefaultModal,
    DynamicFormContent,
  },

  data() {
    return {
      loading: true,
      companies: [],
      options: {
        sortBy: ['key'],
        sortDesc: [false],
      },
      itemsLength: 0,
      actions: [
        {
          text: "create_company",
          eventToEmit: "create-new",
        },
      ],
    }
  },

  mounted() {
    this.loading = true
    this.[CompanyMutations.SET_SELECTED_COMPANY](null)

    this.[TenantActions.GET_ALL_TENANTS]()
      .then((tenants) => {
        this.[CompanyMutations.SET_FIELD_TENANTS](tenants.items)
        this.reloadData()
      })
  },

  watch: {
    '$route': function() {
      this.loading = true
      this.reloadData()
    },

    options: {
      handler () {
        this.reloadData()
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('app', [
      'getIconForCoreSettingsItem'
    ]),

    ...mapState({
      fields: state => state.core.company.fields,
      headers: state => state.core.company.tableHeaders,
      tenants: state => state.core.tenant.tenants,
      selectedCompany: state => state.core.company.selectedCompany,
      inactiveFilterValue: state => state.core.company.inactiveFilterValue
    }),

    breadcrumbs() {
      return [
        {
          text: this.$t('companies'),
          prependIcon: this.getIconForCoreSettingsItem('companies'),
        },
      ]
    }
  },

  methods: {
    ...mapActions('core/tenant', [
      TenantActions.GET_ALL_TENANTS
    ]),

    ...mapActions('core/company', [
      CompanyActions.SAVE_COMPANY,
      CompanyActions.GET_ALL_COMPANIES,
      CompanyActions.DELETE_COMPANY
    ]),

    ...mapActions('app', [
      AppActions.OPEN_APP_SUCCESS_MESSAGE
    ]),

    ...mapMutations('core/company', [
      CompanyMutations.SET_SELECTED_COMPANY,
      CompanyMutations.SET_FIELD_TENANTS,
      CompanyMutations.SET_INACTIVE_FILTER_VAL
    ]),

    newCompany() {
      this.[CompanyMutations.SET_SELECTED_COMPANY](null)
      this.$refs.modal.openModal()
    },

    save() {
      this.$refs.modal.errorMessage = null
      this.$refs.modal.errorList = []

      this.[CompanyActions.SAVE_COMPANY]()
        .then(() => {
          let message = this.selectedCompany ? 'company_edited' : 'company_created'
          
          this.[AppActions.OPEN_APP_SUCCESS_MESSAGE](this.$t(message))

          this.$refs.modal.closeModal()

          this.reloadData()
        })
        .catch(() => {
          this.$refs.modal.submitting = false
        })
    },

    reloadData() {
      let options = {...this.$route.query}

      if (this.options.sortBy.length > 0) {
        options.order = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0 && this.options.sortDesc[0]) {
        options.direction = 'DESC'
      }

      options.limit = this.options.itemsPerPage
      options.offset = this.options.itemsPerPage * (this.options.page - 1)

      if (!this.inactiveFilterValue) {
        options = {
          ...options,
          'filter[active]' : "true"
        }
      }

      this.loading = true
      this.[CompanyActions.GET_ALL_COMPANIES](options)
        .then((result) => {
          this.companies = result.items
          this.itemsLength = result.total
          this.loading = false
        })
        .catch(() => this.loading = false)
    },

    switchInactive() {
      this.[CompanyMutations.SET_INACTIVE_FILTER_VAL](!this.inactiveFilterValue)
      this.reloadData()
    },

    editCompany(company) {
      this.[CompanyMutations.SET_SELECTED_COMPANY](company)
      this.$refs.modal.openModal()
    },

    getTenantTitle(id) {
      let tenant = this.tenants.find(f => f.tenant_id == id)

      return tenant ? tenant.title : ''
    },

    getModalTitle() {
      return this.selectedCompany ? this.$t('edit_company') : this.$t('new_company')
    },

    itemRowStyle(item) {
      return item.active ? '': 'inactive-row'
    },
  }
}

</script>
